import React from 'react'
import SoicalIcon from '../../Components/SoicalIcon'
import VideoBG from '../../Assets/video/bgvideo.mp4'
import { ComingSoonData } from '../../Constant'
import './style.css'

function ComingSoon() {
  return (
    <div className="coming-wrapper">
      <video width="100%" playsInline muted autoPlay loop>
        <source src={VideoBG} type="video/mp4" />
      </video>
      <section className="coming-soon">
        <div className="soon-card">
          <img className="Site-logo" src={ComingSoonData.image} alt="Logo" />
          <h1 className="H2heading">{ComingSoonData.title}</h1>
          <span className="subTitle">{ComingSoonData.subTitle}</span>
          <p className="para">{ComingSoonData.para}</p>
          <SoicalIcon data={ComingSoonData.soicalList} />
        </div>
      </section>
    </div>
  )
}

export default ComingSoon
