import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import Error from '../Screens/Error/Error'
// import Home from '../screens/Home'
import ComingSoon from '../Screens/ComingSoon'

const Routes = () => {
  return useRoutes([
    {
      path: '/',
      element: <ComingSoon />
    },
    { path: '404', element: <Error /> },
    {
      path: '*',
      element: <Navigate to="/404" />
    }
  ])
}

export default Routes
