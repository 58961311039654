import { BrowserRouter } from 'react-router-dom'
// import Footer from './components/Footer/footer'
// import Header from './components/header/header'
import Routes from './Routes/routes'

import './App.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <Header /> */}
        <Routes />
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  )
}

export default App
