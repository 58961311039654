import Logo from '../Assets/images/Logo.svg'
import Twitter from '../Assets/images/Twitter.svg'
import github from '../Assets/images/github.svg'
import mail from '../Assets/images/mail.svg'

export const ComingSoonData = {
  image: Logo,
  title: 'COMING SOON',
  subTitle: 'Revolutionize Ethereum with Fluxnet - the ultimate scaling solution.',
  para: 'Follow us to stay up to date with the latest news and developments.',
  soicalList: [
    {
      images: Twitter,
      title: 'Twitter',
      link: 'https://twitter.com/Fluxnet_Tech'
    },
    {
      images: github,
      title: 'github',
      link: 'https://github.com/fluxnet0'
    },
    {
      images: mail,
      title: 'mail',
      link: 'mailto:contact@fluxnet.technology?subject=Contact%20Form%20Fluxnet&body=Your%20Message%20Body'
    }
  ]
}
